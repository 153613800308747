import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
    Agent_Cypher: "",
    registerKey: false
}

const GetAgentCypherStore = (state, action) => {
    return updateObject(state, { Agent_Cypher: action?.AgentCypherStore });
};

const GetAgentRegisterKeyStore = (state, action) => {
    return updateObject(state, { registerKey: action?.registerKeyStore });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        // leadtchh
            case actionTypes.AGENT_CYPHER_STORE:
            return GetAgentCypherStore(state, action);
            case actionTypes.AGENT_REGISTER_KEY_STORE:
            return GetAgentRegisterKeyStore(state, action);
        default:
            return state;
    }
};

export default reducer;